'use client'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { ReactNode, forwardRef, useState } from 'react'
import { ActionMeta, MultiValue, SingleValue } from 'react-select'
import { MultipleSelect } from '~/core/ui/MultipleSelect'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { SelectProps } from '~/core/ui/Select'
import { ISelectOption } from '~/core/ui/Select'
import { useEscapeHandler } from '../hooks/useEscapeHandler'

interface DropdownOptionProps {
  defaultOpen?: boolean
  trigger?: ReactNode
  renderTrigger?: (args: {
    onOpenChange: (open: boolean) => void
    open: boolean
  }) => ReactNode
  side?: 'top' | 'right' | 'bottom' | 'left'
  align?: 'start' | 'center' | 'end'
  sideOffset?: number
  className?: string
  triggerClassName?: string
  configSelect: SelectProps
  isCloseDropdownOnSelect?: boolean
  dropdownMenuContainer?: HTMLElement
  closeModal?: () => void
}

const DropdownOption = forwardRef<
  React.ElementRef<typeof DropdownMenu.Content>,
  DropdownOptionProps
>(
  (
    {
      trigger,
      renderTrigger,
      side = 'top',
      align = 'start',
      sideOffset = 5,
      className,
      triggerClassName,
      configSelect: { value, onChange, destructive, ...otherConfigSelect },
      isCloseDropdownOnSelect = false,
      dropdownMenuContainer,
      defaultOpen = false,
      closeModal = () => {}
    },
    ref
  ) => {
    const [open, onOpenChange] = useState(defaultOpen)

    const mergedOptions = {
      isDropdown: true,
      autoFocus: true,
      showDropdownIndicator: false,
      showClearIndicator: false,
      backspaceRemovesValue: false,
      controlShouldRenderValue: false,
      hideSelectedOptions: false,
      isClearable: false,
      menuIsOpen: true,
      tabSelectsValue: false,
      flag: true,
      value,
      onChange: (
        newValue: SingleValue<ISelectOption> | MultiValue<ISelectOption> | null,
        actionMeta: ActionMeta<ISelectOption>
      ) => {
        if (actionMeta?.option?.disabled) {
          return
        }
        if (isCloseDropdownOnSelect) {
          onOpenChange(false)
        }
        onChange(newValue, actionMeta)
      },
      ...otherConfigSelect
    }

    useEscapeHandler({
      isDropdownOpen: open,
      closeDropdown: () => onOpenChange(false),
      closeModal,
    })

    return (
      <DropdownMenu.Root
        open={open}
        onOpenChange={(open) => {
          onOpenChange(open)
        }}>
        {trigger ? (
          <DropdownMenu.Trigger asChild>
            <div className={triggerClassName}>{trigger}</div>
          </DropdownMenu.Trigger>
        ) : null}
        {renderTrigger && <>{renderTrigger({ onOpenChange, open })}</>}

        <DropdownMenu.Portal container={dropdownMenuContainer}>
          <DropdownMenu.Content
            ref={ref}
            align={align}
            side={side}
            sideOffset={sideOffset}
            onEscapeKeyDown={() => onOpenChange(false)}
            className={`rounded bg-white shadow-ats dark:bg-gray-900 dark:shadow-dark-ats my-ignore-action ${className}`}>
            {otherConfigSelect?.isMulti ? (
              <MultipleSelect {...mergedOptions} />
            ) : (
              <NativeSelect {...mergedOptions} />
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    )
  }
)

DropdownOption.displayName = 'DropdownOption'

export { DropdownOption }
export type { DropdownOptionProps }
